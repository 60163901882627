import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const NotFound = () => {
    return(
    <Layout>
        <h2>Oh dear, it seems that page does not exist yet.</h2>
        <p><Link to = '/'>Let's head home instead</Link> ;–]</p>
    </Layout>
    )
}

export default NotFound